<template>

  <div :class="'container-fluid dashboard--config-page mobile_' + isMobile()">

    <!-- CONTENT : HEADER -->

    <FarmgodHeader>
      <div class='row'>
        <div class='col-sm-12'>
          <Logo v-bind:link="'overview'" v-bind:title="'Powered by FarmGod.io'"/>
        </div>
      </div>
      <div class='row'>
        <section class='col alert alert-danger pulsate fI-slow' v-if='liveStatErrorMessage'>
          <b><translate>LIVESTAT-ERROR</translate> {{ liveStatErrorMessage.error_code }}</b><br/>{{ liveStatErrorMessage.error_detail }}
        </section>
        <section class='col alert alert-info pulsate fI-slow' v-if='!liveStatErrorMessage && latestStatMessage.miner_count_online === 0'>
          <b><translate>ATTENTION</translate></b><br/><translate>No miners online!</translate>
        </section>
        <NavBar v-bind:farm="farm" />
      </div>
    </FarmgodHeader>

    <!-- CONTENT : CORE -->

    <div class='row' style="margin-bottom:15px;">

      <!-- CONTENT : SIDEBAR LEFT -->

      <div v-if='!isMobile()' class='col-2'>
        <SidebarLeft v-bind:farm="farm" v-bind:latestStatMessage="latestStatMessage" />
      </div>

      <!-- CONTENT : CONFIGURATION -->

      <div :class="!isMobile() ? 'col-8' : 'col-sm-12'">

        <div class="container_wrapper">

          <div class="container_header_detail">
            <span class="fI"><i class="fas fa-cogs fas-left"></i> <translate>GLOBAL MINER SETTINGS</translate></span>
          </div>

          <div class="container_body_detail" style="margin-bottom: 0px; padding: 0px 0px 0 20px;">

            <FormulateForm
              v-if='minerConfig'
              @submit="submitFormHandler"
              id="cfgForm"
              style='overflow: hidden;'
              :values='minerConfig'
              ref='configForm'
              :errors='minerConfigErrors'
              @failed-validation='scrollToErrors'
              :debounce='200'
            >

            <div id="visibleConfig" class="overflow-auto fI-slow">

              <div style="margin-right: 20px;">

                <span style="display:none;">

                  <label class="wideLabel"><i class="fas fas-xsBtn fas-left fa-users-cog"></i>BASIC SETUP</label>

                  <FormulateInput
                      :label="$gettext('DATA UPDATE FREQUENCY')"
                      :tooltip="$gettext('The frequency in which miner stats will be updated throughout the interface.')"
                      type='select'
                      :options="{ '15': '15 Seconds' }"
                      name='telemetry_submit_period'
                      disabled
                      style="opacity: 0.7; cursor: not-allowed;"
                  />

                  <FormulateInput
                      type='hidden'
                      name='disable_autoupgrade'
                      :value='minerConfig.disable_autoupgrade'
                  />

                </span>

                <label class='wideLabel' style="margin-bottom:0px;"><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>POOL SETTINGS</translate></label>

                <FormulateInput
                    type='group'
                    name='pool_groups'
                    #default="{ model, index }"
                    :key='poolGroupConfigsKey(0)'
                    >

                    <fieldset
                        class='configGrp'
                        v-if='minerConfig.pool_groups[index]'>

                      <FormulateInput
                          name='id'
                          type='hidden'
                      />

                      <FormulateInput
                          name='name'
                          type='hidden'
                      />

                      <FormulateInput
                          type='hidden'
                          name='work_distribution_mode'
                      />

                      <FormulateInput
                          v-if='minerConfig.pool_groups[index].work_distribution_mode === "quota"'
                          name='quota'
                          type='hidden'
                      />

                      <FormulateInput
                          v-else
                          name='fixed_share_ratio'
                          type='hidden'
                      />

                      <div ref='poolConfig' class='scroll-anchor configGrp'>

                        <FormulateInput
                            type='group'
                            name='pool_configs'
                            :repeatable='false'
                            #default="{ model, index }"
                            >

                            <fieldset class='configGrp' v-if='index !== null && model && model[index] && model[index].id'>

                              <legend class="subSubLabel uc">
                                <a class="delPoolConfig" @click="deletePoolConfig(model[index].id)"><i class="fas fas-xsBtn fas-left fa-minus-circle"></i></a><translate>Pool</translate> #{{ index+1 }}
                              </legend>

                              <FormulateInput
                                  name='id'
                                  type='hidden'
                                  />

                              <!--FormulateInput
                                  :label="$gettext('ENABLED')"
                                  :tooltip="$gettext('Initial state of the pool after initialization. (default=enabled)')"
                                  name='enabled'
                                  class="enabledCheck"
                                  type='checkbox'
                                  label-position="before"
                              /-->

                              <FormulateInput
                                  :label="$gettext('POOL URL')"
                                  autocomplete="new-password"
                                  :tooltip="$gettext('Mandatory argument for server URL specified in the format: \'scheme://HOSTNAME:PORT/POOL_PUBLIC_KEY\'')"
                                  name='url'
                                  type='url'
                                  @blur='setPoolApiFromHost(index)'
                                  :validation="[['matches', /^(drain|stratum\+tcp|stratum\+ssl|stratum\+insecure|stratum2\+tcp|http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/]]"
                                  :validation-messages="{ matches: 'Pool url must match a valid pool url scheme' }"
                                  placeholder='eg. stratum+tcp://stratum.antpool.com:3333'
                                  :debounce='200'
                              />

                              <FormulateInput
                                  :label="$gettext('POOL USER')"
                                  :tooltip="$gettext('Mandatory argument for username specified in format: \'USERNAME\'')"
                                  :validation="[['matches', /^[a-zA-Z0-9_\-]+$/]]"
                                  :validation-messages="{ matches: 'Username must only contain alpha numerics, underscores and dashes.' }"
                                  autocomplete="new-password"
                                  name='username'
                                  type='text'
                                  :placeholder="$gettext('eg. myusername')"
                                  :debounce='200'
                              />

                              <FormulateInput
                                  :label="$gettext('POOL PASSWORD')"
                                  :tooltip="$gettext('Optional password settings.')"
                                  autocomplete="new-password"
                                  name='password'
                                  type='text'
                                  :placeholder="$gettext('optional')"
                                  :debounce='200'
                                />

                              <FormulateInput
                                  :label="$gettext('API-KEY')"
                                  :tooltip="$gettext('Optional api-key (Slushpool, Antpool, ...).')"
                                  autocomplete="new-password"
                                  name='api_key'
                                  type='text'
                                  :placeholder="$gettext('optional')"
                                  :debounce='200'
                                />

                              <FormulateInput
                                  name='api'
                                  type='hidden'
                                  :value='model[index].url ? getPoolApiFromHost(model[index].url) : null'
                                />

                            </fieldset>

                        </FormulateInput>

                        <div v-if='minerConfig.pool_groups[0].pool_configs.length < 3' style="display: inline-flex; width: 100%;">
                          <a class="new-pool-button btn btn-info" @click="addPoolConfigForPoolGroup(index)"><i class="fas fas-xsBtn fas-left fa-plus-circle"></i><translate>POOL</translate></a>
                        </div>
                      </div>

                    </fieldset>

                </FormulateInput>

                <fieldset ref='fanConfig' class='scroll-anchor configGrp' style="display:none;">
                  <label class='wideLabel'><font-awesome-icon class='fas-left' icon='tachometer-alt'/><translate>FAN CONFIG</translate></label>
                  <FormulateInput
                      :tooltip="$gettext('Set custom fan speed in percent. (Default: Disabled)')"
                      :label="$gettext('CUSTOMIZE')"
                      type='checkbox'
                      name='custom_fanspeed'
                      label-position="before"
                  />
                  <FormulateInput
                      :tooltip="$gettext('Set a fixed fan speed in % (default=70). This option is NOT used when temp_control.mode is set to \'auto\'!')"
                      :label="$gettext('FAN SPEED')"
                      autocomplete="new-password"
                      name='fans_pct'
                      validation='optional|min:0,value|max:100,value'
                      error-behavior="value"
                      type='number'
                      :placeholder="$gettext('Use Device-Default...')"
                      step='1'
                  />
                </fieldset>

              </div>

              </div>

              <ConfigFooter
                :isMobile='isMobile()'
                :parentRefs='$refs'
                />

            </FormulateForm>

          </div>

        </div>

      </div>

      <!-- CONTENT : SIDEBAR RIGHT -->

      <div v-if='!isMobile()' class='col-2'>
        <SidebarRight v-bind:latestStatMessage="latestStatMessage" v-bind:farm="farm" />
      </div>
    </div>

    <FarmgodFooter>
      <LoggedInFooter />
    </FarmgodFooter>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->

    <Modal ref="configUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 3em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 3px; font-size: 1em; margin-top: 9px; margin-bottom: 10px;color: #13A454;"> <translate>UPDATE SUCCESSFUL</translate> </div>
            <div style="width: 60%; margin: auto;"><small><span class="grey"><translate>During the configuration update process, data (eg. pool status, stats) might be displayed inaccurately for several minutes.</translate></span></small></div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.configUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->

    <Modal ref="configUpdateError" :errorDetail='this.$data.configUpdateError'>
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
              <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
              <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.configUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FARM__READ, MINER_CONFIG__UPDATE__PRO, FARM__READ_MINER_CONFIG__PRO, LIVE_STAT__DISCONNECT, LIVE_STAT__CONNECT } from '@/store/actions.type'

import Farm from '@/models/farm'
import MinerConfig from '@/models/miner-config-pro'
import PoolGroup from '@/models/miner-config/pool-group-pro'
import PoolConfig from '@/models/miner-config/pool-config-pro'
// import FanConfig from '@/models/miner-config/fans'

import FarmgodHeader from '@/components/layout/TheHeader'
import NavBar from '@/components/views/Detail/NavBar'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedInFooter from '@/components/layout/Footers/LoggedIn'
import SidebarLeft from '@/components/layout/SidebarLeft'
import SidebarRight from '@/components/layout/SidebarRight'
import Modal from '@/components/widgets/Modal.vue'

import ConfigFooter from '@/components/views/Detail/Config/ProFooter'

export default {
  name: 'pro-miner-config',
  components: {
    FarmgodHeader,
    Logo,
    NavBar,
    SidebarLeft,
    SidebarRight,
    FarmgodFooter,
    LoggedInFooter,
    ConfigFooter,
    Modal
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    scrollToErrors () {
      document.querySelector('.formulate-input-error').scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    addPoolConfigForPoolGroup (index) {
      const pG = this.minerConfig.pool_groups[index]
      PoolConfig.insert({
        data: {
          poolgroup_id: pG.id
        }
      })
    },
    poolGroupConfigsKey (index) {
      if (this.minerConfig.pool_groups[index]) {
        return this.minerConfig.pool_groups[index].pool_configs.length
      } else {
        return Date.now()
      }
    },
    updateWorkDistributionSettings (index, wdm) {
      const item = this.minerConfig.pool_groups[index]
      if (wdm === 'quota') {
        PoolGroup.update({
          where: item.id,
          data: { work_distribution_mode: wdm, fixed_share_ratio: null }
        })
      } else {
        PoolGroup.update({
          where: item.id,
          data: { work_distribution_mode: wdm, quota: null }
        })
      }
    },
    deletePoolConfig (id) {
      PoolConfig.delete(id)
    },
    getPoolApiFromHost (url) {
      let name = null
      url.includes('antpool') ? name = 'antpool' : name = null
      return name
    },
    setPoolApiFromHost (index) {
      this.minerConfig.pool_groups[0].pool_configs[index].api = this.getPoolApiFromHost(this.minerConfig.pool_groups[0].pool_configs[index].url)
    },
    async submitFormHandler (data) {
      await this.$store.dispatch(MINER_CONFIG__UPDATE__PRO, { configId: this.minerConfig.id, configData: data }).then((result) => {
        PoolGroup.deleteAll()
        this.$refs.configUpdateSuccess.openModal()
        this.$store.dispatch(FARM__READ_MINER_CONFIG__PRO, this.$route.params.farm_id)
      }).catch((err) => {
        if (!err.response) {
          throw err
        }
        this.errorDetailFull = JSON.parse(JSON.stringify(err.response.data))
        this.errorDetail = this.errorDetailFull.detail[0].msg
        this.$refs.configUpdateError.openModal()
      })
    }
  },
  computed: {
    ...mapGetters({ latestStatMessage: 'livestats/latestStatMessage', liveStatErrorMessage: 'livestats/liveStatErrorMessage' }),
    farm () {
      const farm = Farm.find(this.$route.params.farm_id)
      if (farm) {
        return farm
      } else {
        this.$store.dispatch(FARM__READ, this.$route.params.farm_id)
        return null
      }
    },
    minerConfigErrors () {
      const errors = this.minerConfig.errors

      const poolGroupErrors = []
      this.minerConfig.pool_groups.forEach((poolGroup) => {
        const pgErrors = poolGroup.errors
        pgErrors.pool_configs = []

        poolGroup.pool_configs.forEach((poolConfig) => {
          pgErrors.pool_configs.push(poolConfig.errors)
        })

        poolGroupErrors.push(poolGroup.errors)
      })

      errors.pool_groups = poolGroupErrors

      return errors
    },
    minerConfig () {
      const minerConfig = MinerConfig.query()
        .with('pool_groups')
        .with('pool_groups.pool_configs')
        .where({ deviceset_id: this.$route.params.farm_id })
        .first()

      return minerConfig
    }
  },
  beforeDestroy () {
    MinerConfig.deleteAll()
  },
  created () {
    this.$store.dispatch(FARM__READ_MINER_CONFIG__PRO, this.$route.params.farm_id)
    this.$store.dispatch(`livestats/${LIVE_STAT__DISCONNECT}`, this.$route.params.farm_id)
    this.$store.dispatch(`livestats/${LIVE_STAT__CONNECT}`, { farmId: this.$route.params.farm_id })
  }
}
</script>
